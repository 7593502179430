import React, { useEffect, useState, useRef } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import BookmarkStoryCard from "../../molecules/bookmark-story-card";
import Pagination from "../../molecules/pagination";
import { putUserDetails } from "../../helper/utils";
import { setLoading, setUserDetails } from "../../store/actions";
import "./bookmark.m.css";

function BookmarkPage() {
  const dispatch = useDispatch();

  const isUserLogin = useSelector((state) => get(state, ["isLogin"], false));
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));

  const [tempData, setTempData] = useState([]);
  const [updateData, setUpdateData] = useState({});

  const ref = useRef();
  const bookmarkedStories = userdetails?.metadata?.savedArticles || [];
  const [bookmarks, setBookmarks] = useState([]);
  const bookmarksPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [redirectURL, setRedirectUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [bookmarkloading, setBookmarkloading] = useState(true);
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  useEffect(() => {
    if (window) {
      setRedirectUrl(window.location.href);
      const token = window.localStorage.getItem("access_token");

      let user;
      const localUser = window?.localStorage?.getItem("user");
      if (localUser !== "undefined") {
        if (typeof localUser === "string" && localUser.trim() !== "") {
          user = JSON.parse(localUser);
        }
      }
      if (user?.userData?.id?.length > 0) {
        setBookmarkloading(false);
      }
      setBookmarks(user?.metadata?.savedArticles || []);
      setAccessToken(token || "");
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get("page") || 1;
      setCurrentPage(Number(page));
    }
  }, [bookmarkedStories]);

  useEffect(() => {
    if (bookmarkedStories.length > 0) {
      const reversedBookmarks = [...(bookmarkedStories || [])].reverse();
      setBookmarks(reversedBookmarks);
    }

    setUpdateData({ savedArticles: [...(bookmarkedStories || [])] });
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        let localUserData;
        const user = window?.localStorage?.getItem("user");
        if (user !== "undefined") {
          if (typeof user === "string" && user.trim() !== "") {
            localUserData = JSON.parse(user);
          }
        }
        const finalData = updateData?.savedArticles.filter(
          (story) => !tempData?.some((tempStory) => tempStory?.id === story?.id)
        );

        setUpdateData({ savedArticles: finalData });
        const savedArticlesLength = finalData.length;
        localStorage.setItem("bookmarksLength", savedArticlesLength);

        dispatch(
          setUserDetails({ ...localUserData, metadata: { ...localUserData?.metadata, savedArticles: finalData } })
        );
        window.localStorage.setItem(
          "user",
          JSON.stringify({ ...localUserData, metadata: { ...localUserData?.metadata, savedArticles: finalData } })
        );
        putUserDetails({ savedArticles: finalData });

        setBookmarkloading(true);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [tempData]);

  const signinHandler = () => {
    if (window) {
      window.dataLayer.push({
        event: "SSO_Page_Open"
      });
    }
    window.location.href = `${ssoEndpoint}/auth?redirectUrl=${redirectURL}`;
  };

  const paginatedBookmarks = bookmarks.slice((currentPage - 1) * bookmarksPerPage, currentPage * bookmarksPerPage);

  const loadMoreBookmarks = (page) => {
    dispatch(setLoading(true));
    window.history.pushState(null, "", `?page=${page}`);

    ref.current?.scrollIntoView({
      behavior: "smooth"
    });
    setCurrentPage(page);
    dispatch(setLoading(false));
  };

  const addBookmarkStoryHandler = (story) => {
    const bookmarkStories = tempData?.filter((article) => {
      return article?.id !== story?.id;
    });
    setTempData(bookmarkStories);
  };

  const removeBookmarkStoryHandler = (story) => {
    let localUserData;
    const user = window?.localStorage?.getItem("user");
    if (user !== "undefined") {
      if (typeof user === "string" && user.trim() !== "") {
        localUserData = JSON.parse(user);
      }
    }
    const unbookmarkStory = localUserData?.metadata?.savedArticles?.find((article) => {
      return article?.id === story?.id;
    });
    setTempData([...tempData, unbookmarkStory]);
  };

  return (
    <div className="container" styleName="bookmark-wrapper">
      <div styleName="title">
        <h2 ref={ref}>ಬುಕ್‌ಮಾರ್ಕ್</h2>
      </div>

      {accessToken ? (
        <>
          {" "}
          {bookmarkloading ? (
            <div styleName="no-bookmark-container "></div>
          ) : (
            <>
              {paginatedBookmarks?.length > 0 ? (
                <>
                  <div styleName="bookmark-stories">
                    {paginatedBookmarks?.map((item, index) => (
                      <BookmarkStoryCard
                        story={item}
                        key={index}
                        addBookmark={addBookmarkStoryHandler}
                        removeBookmark={removeBookmarkStoryHandler}
                      />
                    ))}
                  </div>
                  <div styleName="bookmarks-pagination">
                    <Pagination
                      loadMoreStories={loadMoreBookmarks}
                      totalItems={bookmarks.length}
                      perPage={bookmarksPerPage}
                      initialPage={currentPage}
                    />
                  </div>
                </>
              ) : (
                <div styleName="no-bookmark-container">
                  <h2 styleName="no-bookmark-message">ಯಾವುದೇ ವಿಷಯವನ್ನೂ ಬುಕ್‌ಮಾರ್ಕ್ ಮಾಡಲಾಗಿಲ್ಲ</h2>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div styleName="no-bookmark-container">
          <h2 styleName="sigin-bookmark-msg">
            <span onClick={signinHandler}>Sign in</span> to view your bookmarked stories
          </h2>
        </div>
      )}
    </div>
  );
}

export { BookmarkPage };
