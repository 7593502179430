import React, { useEffect, useState, useRef } from "react";
import { string, shape, object } from "prop-types";
import get from "lodash/get";
import { useDispatch } from "react-redux";

import SideBarContent from "../../molecules/sidebar-content";

import { AdContainer } from "../../ads";
import TaboolaAd from "../../ads/taboola-ad";
import TaboolaScript from "../../ads/taboola-ad/load-taboola-ad";

import { getCollection } from "../../helper/api";
import { setLoading } from "../../../components/store/actions";
import LatestNews from "../../story-templates/story-elements/latest-news";
import ListingPageTemplate from "../../collection-templates/listing-page-template";

import "./sub-section-page.m.css";
import useDevice from "../../helper/custom-hook/useDevice";
import CollectionWidget from "../../collection-templates/collection-widget";

const SideBarComponent = () => {
  const [showSidebar, setShowSideBar] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSideBar(true);
    }, 3500);
  }, []);
  return (
    <div className="video-element">
      {showSidebar && <SideBarContent title="ಪ್ರಜಾವಾಣಿ ವಿಡಿಯೊ" speed={2500} type={"video"} />}
    </div>
  );
};
const SubSectionPage = ({ data }) => {
  const subSectionStories = data.collection?.items?.map((i) => i.story);
  const [stories, setStories] = useState(subSectionStories || []);
  const dispatch = useDispatch();
  const ref = useRef();
  const totalCount = get(data, ["collection", "total-count"], 0);
  const cardsPerPage = 10;
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(true);

  const isMobile = useDevice();

  if (!stories.length) {
    return <h1 styleName="text-info">ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಲಭ್ಯವಿಲ್ಲ</h1>;
  }

  const loadStories = async (page) => {
    const offset = (page - 1) * cardsPerPage;
    const slug = data.collection.slug;

    dispatch(setLoading(true));
    ref.current?.scrollIntoView({
      behavior: "smooth"
    });

    await getCollection(slug, offset, cardsPerPage)
      .then((i) => setStories(i))
      .finally(() => dispatch(setLoading(false)));
  };

  const SectionName = data?.section?.name;
  const CollectionName = data?.collection?.name;

  const showHardcodedContent = data?.collection?.slug == "delhi-elections";

  return (
    <>
      <div className="container">
        {showHardcodedContent && <CollectionWidget isCustomContent={showHardcodedContent} />}
      </div>
      <div className="container" styleName="wrapper">
        <TaboolaScript type="category" />
        <div>
          <div ref={ref} styleName="title">
            <h1>{SectionName || CollectionName}</h1>
          </div>

          <ListingPageTemplate
            loadStories={loadStories}
            storiesList={stories}
            perPage={cardsPerPage}
            totalCount={totalCount}
          />
          {renderTaboolaAd ? (
            <div>
              <div className="hide-mobile">
                <TaboolaAd
                  container="taboola-below-category-thumbnail--pj"
                  placement="Below Category Thumbnail- PJ"
                  mode="alternating-thumbnails-a"
                />
              </div>

              <div className="hide-desktop">
                <TaboolaAd
                  container="taboola-below-category-thumbnail--pj-mobile"
                  placement="Below Category Thumbnail - PJ Section Mobile"
                  mode="thumbnails-c-carousel"
                />
              </div>
            </div>
          ) : null}
        </div>
        {!isMobile && (
          <div styleName="latest-wrapper-section">
            <div className="hide-mobile ad">
              <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675836921322-0" desktop="PV_Desktop_HP_RT" />
            </div>

            <SideBarComponent />

            <LatestNews />

            <div className="hide-mobile ad">
              <AdContainer AdClassName="standard-300x250" id="div-gpt-ad-1675837026472-0" desktop="PV_Desktop_HP_RM" />
            </div>
            {renderTaboolaAd ? (
              <div className="hide-mobile">
                <TaboolaAd
                  container="taboola-right-rail-thumbnails"
                  placement="Right Rail Thumbnails"
                  mode="thumbnails-rr"
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

SubSectionPage.propTypes = {
  pageType: string,
  data: shape({
    collection: object,
    section: string
  })
};

export { SubSectionPage };
